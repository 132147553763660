const BACKEND_URL = process.env.NEXT_PUBLIC_API_URL
const BLOG_BACKEND_URL = process.env.NEXT_PUBLIC_API_BLOG_URL
const RESEARCH_BACKEND_URL = process.env.NEXT_PUBLIC_API_RESEARCH_URL
const LEARN_BACKEND_URL = process.env.NEXT_PUBLIC_API_LEARN_URL
const CUSTOMER_BACKEND_URL = process.env.NEXT_PUBLIC_API_CUSTOMER_URL
const NOTION_URL = process.env.NEXT_PUBLIC_NOTION_URL
const SME_URL = process.env.NEXT_PUBLIC_API_SME_URL

export default {
  COUNTRIES: `${BACKEND_URL}/portal/v1/resources/countries`,
  PRODUCTS: `${BACKEND_URL}/portal/v1/resources/projects/products`,
  PROJECT_STATUS: `${BACKEND_URL}/portal/v1/resources/projects/public_status`,
  INDUSTRY: `${BACKEND_URL}/portal/v1/resources/organizations/industry_categories`,
  RESOURCE_LANG: `${BACKEND_URL}/v3/resources/lang`,
  REGISTER: `${BACKEND_URL}/portal/v1/users/register`,

  BLOG_CATEGORIES: `${BLOG_BACKEND_URL}/wp/v2/categories`,
  BLOG_POST: `${BLOG_BACKEND_URL}/wp/v2/posts`,
  BLOG_USERS: `${BLOG_BACKEND_URL}/wp/v2/users`,
  BLOG_SUBSCRIBE: `${BACKEND_URL}/portal/v1/blog/subscribe`,
  NEW_BLOG_SUBSCRIBE: `${BACKEND_URL}/portal/v1/mail/subscribers`,
  BLOG_TAGS: `${BLOG_BACKEND_URL}/wp/v2/tags`,

  RESEARCH_CATEGORIES: `${RESEARCH_BACKEND_URL}/v2/categories`,
  RESEARCH_POST: `${RESEARCH_BACKEND_URL}/v2/posts`,
  RESOURCE_TAGS: `${RESEARCH_BACKEND_URL}/v2/tags`,

  LEARN_CATEGORIES: `${LEARN_BACKEND_URL}/v2/categories`,
  LEARN_POST: `${LEARN_BACKEND_URL}/v2/posts`,

  CUSTOMER_POSTS: `${CUSTOMER_BACKEND_URL}/v2/posts`,
  CUSTOMER_CATEGORIES: `${CUSTOMER_BACKEND_URL}/v2/categories`,
  CUSTOMER_TAGS: `${CUSTOMER_BACKEND_URL}/v2/tags`,

  CUSTOMER_CASES: `${BACKEND_URL}/portal/v1/public/case_studies`,
  CUSTOMER_CASE_CATEGORIES: `${BACKEND_URL}/portal/v1/resources/organizations/industry_categories`,

  PUBLIC_PROJECTS: `${BACKEND_URL}/portal/v1/public/projects`,
  PUBLIC_LIBRARY: `${BACKEND_URL}/portal/v1/public/library`,

  NOTION: NOTION_URL,

  MAIL_LISTS: `${BACKEND_URL}/portal/v1/mail/lists`,
  SUBSCRIBER_INFO: `${BACKEND_URL}/portal/v1/mail/subscribers`,
  UPDATE_SUBSCRIBER: `${BACKEND_URL}/portal/v1/mail/subscribers`,
  UNSUBSCRIBE: `${BACKEND_URL}/portal/v1/mail/subscribers`,

  RESOURCE_VULN_PLUGINS: `${BACKEND_URL}/v4/resources/vuln_plugins`,
  RESOURCE_VULN_PLUGINS_FILTERS: `${BACKEND_URL}/v4/resources/vuln_plugins/filters`,

  RESOURCE_INDUSTRIES: `${BACKEND_URL}/v4/public/free_scan/industries`,
  VULNSCAN_REPORT_DOMAIN: `${BACKEND_URL}/v4/public/free_scan/domains`,

  FREE_SCAN: `${BACKEND_URL}/v4/public/free_scan/score`,
  FREE_SCAN_SEARCH: `${BACKEND_URL}/v4/public/free_scan/search`,

  DATA_LEAK_STASTISTICS: `${BACKEND_URL}/v4/public/data_leak/statistics`,
  SME: `${SME_URL}/wp/v2`,
  BLOG_RANKMATH: `${BLOG_BACKEND_URL}/rankmath/v1`,
  SME_RANKMATH: `${SME_URL}/rankmath/v1`,

  TRUST_CENTER: `${BACKEND_URL}/whitehub/v4/trust_centers/:alias`,
}
