import "../styles/index.scss";

import { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import TagManager from "react-gtm-module";
import { useRouter } from "next/router";
import enUS from "antd/locale/en_US";
import viVN from "antd/locale/vi_VN";

import CystackFooter from "../components/layout/Footer";
import CystackHeader from "../components/layout/Header";
import CystackSmeHeader from "../components/layout/SmeHeader";
import LearnNavbar from "@/components/learn/Navbar";
import CustomerNavbar from "@/components/customers/Navbar";
import CareersNavbar from "@/components/careers/Navbar";
import ModalJoinCyStackCommunity from "../components/CystackCommunity";

const PhoneCall = () => (
  <a href="tel:+842471099656" className="fixed z-20 wrapper bottom-24 right-1">
    <div className="shadow-none">
      <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
        <div className="coccoc-alo-ph-circle"></div>
        <div className="coccoc-alo-ph-circle-fill"></div>
        <div className="coccoc-alo-ph-img-circle"></div>
      </div>
    </div>
  </a>
);

function CyStack({ Component, pageProps }) {
  const router = useRouter();
  const { locale } = router;

  const [isOpenCommunity, setIsOpenCommunity] = useState(false);
  const [subdomain, setSubdomain] = useState(null);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-T2W558J" });
    const firstLink = sessionStorage.getItem("first_link");
    if (!firstLink) {
      sessionStorage.setItem("first_link", window.location.href);
    }
  }, []);

  useEffect(() => {
    const isOpenedModalCommunity = sessionStorage.getItem(
      "is-opened-modal-community"
    );

    const includeRoute =
      router.pathname.includes("data-protection") ||
      router.pathname.includes("security-audit") ||
      router.pathname.includes("pentest") ||
      router.pathname === "/";
    if (!isOpenedModalCommunity && locale !== "en" && !includeRoute) {
      const timeout = setTimeout(() => {
        setIsOpenCommunity(true);
        document.body.style.overflow = "hidden";
        sessionStorage.setItem("is-opened-modal-community", "true");
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [locale]);

  const handleCloseModal = () => {
    setIsOpenCommunity(false);
    document.body.style.overflow = "auto";
    sessionStorage.setItem("is-opened-modal-community", "true");
  };

  useEffect(() => {
    if (window.$chatwoot) {
      window.$chatwoot.setLocale(locale);
    } else {
      window.chatwootSettings = window.chatwootSettings || {
        baseDomain: ".cystack.net",
      };
      window.chatwootSettings.locale = locale;
    }
  }, [locale]);

  useEffect(() => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    if (parts.length >= 2 && parts[0] === "sme") {
      setSubdomain(parts[0]);
    } else {
      setSubdomain(null);
    }
  }, []);

  const getLayout =
    Component.getLayout ||
    ((page) => (
      <>
        <ConfigProvider
          locale={locale === "vi" ? viVN : enUS}
          theme={{
            hashed: false,
            token: { colorPrimary: "#2222FF", fontFamily: "Inter" },
          }}
        >
          <PhoneCall />
          {subdomain && subdomain === "sme" ? (
            <CystackSmeHeader />
          ) : !router.pathname.includes("/trust") ||
            router.pathname.startsWith("/trust-center") ? (
            <CystackHeader />
          ) : null}
          {router.pathname.includes("/learn") && <LearnNavbar />}
          {(router.pathname.includes("/project") ||
            router.pathname.includes("/customer")) && <CustomerNavbar />}
          {router.pathname.includes("/careers") && <CareersNavbar />}
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, viewport-fit=cover"
            />
          </Head>
          <ModalJoinCyStackCommunity
            isOpen={isOpenCommunity}
            closeModal={handleCloseModal}
          />
          {page}
          {!router.pathname.includes("/trust") ||
          router.pathname.startsWith("/trust-center") ? (
            <CystackFooter subdomain={subdomain} />
          ) : null}
        </ConfigProvider>
      </>
    ));

  return getLayout(<Component {...pageProps} />);
}

export default appWithTranslation(CyStack);
